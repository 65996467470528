import React from "react"
import Highlight, {defaultProps} from 'prism-react-renderer'
import theme from 'prism-react-renderer/themes/vsDark'
import Prism from "prism-react-renderer/prism";


export default ({children}) => {
    const className = children.props.className || ''
    let language = ''    
    const matches = className.match(/language-(?<lang>.*)/)  
    if(matches && matches.groups && matches.groups.lang) {
      language = matches.groups.lang
    }    

    const code = children.props.children.trim()    

  return (
    <Highlight {...defaultProps} code={code} language={language} theme={theme}>
    {({className, style, tokens, getLineProps, getTokenProps}) => (
      <pre className={className} style={{...style, padding: '20px'}}>
        {tokens.map((line, i) => (
          <div key={i} {...getLineProps({line, key: i})}>
            {line.map((token, key) => (
              <span key={key} {...getTokenProps({token, key})} />
            ))}
          </div>
        ))}
      </pre>
    )}
  </Highlight>
  )
}