import React from "react"
import { graphql, Link } from "gatsby"
import { MDXProvider } from "@mdx-js/react"
import { MDXRenderer } from "gatsby-plugin-mdx"

import Layout from "../components/layout"
import SEO from "../components/seo"
import MastHead from "../components/masthead"
import { Col, Container, Row } from "reactstrap"
import CodeBlock from "../components/code-block"

const shortcodes = {pre: CodeBlock}

const BlogPostTemplate = ({ data, location }) => {
  const siteTitle = data.site.siteMetadata.title
  const post = data.mdx
  const {title, subtitle, hero, date} = post.frontmatter    
  const image =  hero ? hero.childImageSharp.fixed.src : null

  const pFM = data.previous?.frontmatter
  const previousLink = data.previous?.fields.slug
  const nFM = data.next?.frontmatter
  const nextLink = data.next?.fields.slug

  return (
    <Layout title={siteTitle}>
        <SEO
          title={title}
          description={subtitle}
          image={image}
        />
        <MastHead overlay title={title} subtitle={subtitle} image={image} meta={date}/>              
        <article>
          <Container>
            <Row>
              <Col lg="12" md="12" className="mx-auto">
                <MDXProvider components={shortcodes}>
                  <MDXRenderer>{post.body}</MDXRenderer>
                </MDXProvider>                
              </Col>        
            </Row>
            <Row style={{paddingBottom:"20px"}}>              
              <Col>
                {nextLink && 
                  <>
                    <b>
                      &#x21E0; <Link to={nextLink}>{nFM.title}</Link>
                    </b> 
                    <br/>                   
                    {nFM.category.map((category) =>{
                      return (
                        <span className="badge badge-default">{category}</span>
                      )
                    })}
                  </> 
                }                
              </Col>
              <Col style={{textAlign:"right"}}>
                {previousLink && 
                  <>
                    <b>
                      <Link to={previousLink}>{pFM.title}</Link> &#x21E2;
                    </b>
                    <br/>
                    {pFM.category.map((category) => {
                      return (
                        <span className="badge badge-default">{category}</span>
                      )
                    })}
                  </>
                }
              </Col>
            </Row>
          </Container>
        </article>      
    </Layout>
  )
}

export default BlogPostTemplate

export const pageQuery = graphql`
  query BlogPostBySlug($slug: String!, $previous: String, $next: String) {
    site {
      siteMetadata {
        title
      }
    }
    mdx(fields: { slug: { eq: $slug } }) {
      id
      body
      frontmatter {
        title   
        subtitle             
        date(formatString: "MMMM DD, YYYY")                
        hero {
          childImageSharp {
            fixed {
              src
            }
          }
        }
      }
    }
    previous: mdx(fields: { slug: { eq: $previous } }) {
      frontmatter {
        title
        category
      }
      fields {
        slug
      }      
    }
    next: mdx(fields: { slug: { eq: $next } }) {
      frontmatter {
        title
        category
      }
      fields {
        slug
      }
    }
  }
`